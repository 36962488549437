<template>
  <section v-if="data" class="o-price-list">
    <div class="o-price-list__container container">
      <h2 class="o-price-list__title">{{ data.title }}</h2>
      <div class="o-price-list__button">
        <Button
          v-if="data.button.type === 'dialog'"
          modifier="tertiary"
          theme="light"
          @click="requireForm(formList[data.button.value])"
        >
          {{ data.button.title }}
        </Button>

        <Button
          v-if="data.button.type === 'node'"
          :href="data.button.value"
          modifier="primary"
          tag="a"
        >
          {{ data.button.title }}
        </Button>

        <Button
          v-if="data.button.type === 'absolute'"
          :href="data.button.value"
          modifier="primary"
          tag="a"
          target="_blank"
        >
          {{ data.button.title }}
        </Button>
      </div>

      <template v-for="(price, groupIdx) in data.complexities">
        <div v-show="complexitiesReveal(groupIdx) && groupIdx === 0" class="o-price-list__price">
          <div class="o-price-list__price-title">{{ price.title }}</div>
          <div class="o-price-list__price-cost">от {{ price.minCost }} ₽</div>
          <div class="o-price-list__services">
            <div v-for="service in price.items" class="o-price-list__service">
              <Card
                css-class="m-card-price"
                :title="service.title"
                :theme="complexityCardTheme(groupIdx)"
                :tag="service.url ? 'a' : undefined"
                :href="service.url ? service.url : undefined"
              >
                <template #contentHeader> {{ service.title }}</template>

                <template #contentFooter>
                  <span
                    class="m-card-price__price"
                    :class="{ 'm-card-price__price--line-through': service.discount }"
                  >
                    от {{ service.cost }} ₽
                  </span>

                  <span v-if="service.discount" class="m-card-price__discount">
                    от {{ service.cost - service.cost * ((service.discount * 100) / 100) }} ₽
                  </span>

                  <span v-if="service.discount" class="m-card-price__discount-text">
                    скидка {{ service.discount * 100 }}%
                  </span>
                </template>
              </Card>
            </div>
          </div>
        </div>

        <Transition name="o-price-list">
          <div v-show="complexitiesReveal(groupIdx) && groupIdx !== 0">
            <div class="o-price-list__price">
              <div class="o-price-list__price-title">{{ price.title }}</div>
              <div class="o-price-list__price-cost">от {{ price.minCost }} ₽</div>
              <div class="o-price-list__services">
                <div v-for="service in price.items" class="o-price-list__service">
                  <Card
                    css-class="m-card-price"
                    :title="service.title"
                    :theme="complexityCardTheme(groupIdx)"
                    :tag="service.url ? 'a' : undefined"
                    :href="service.url ? service.url : undefined"
                  >
                    <template #contentHeader> {{ service.title }}</template>

                    <template #contentFooter>
                      <span
                        class="m-card-price__price"
                        :class="{ 'm-card-price__price--line-through': service.discount }"
                      >
                        от {{ service.cost }} ₽
                      </span>

                      <span v-if="service.discount" class="m-card-price__discount">
                        от {{ service.cost - service.cost * ((service.discount * 100) / 100) }} ₽
                      </span>

                      <span v-if="service.discount" class="m-card-price__discount-text">
                        скидка {{ service.discount * 100 }}%
                      </span>
                    </template>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </template>

      <Button modifier="tertiary" theme="light" @click="complexityState = !complexityState">
        {{ complexityState ? 'Свернуть' : 'Показать все цены' }}
      </Button>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Vue
import { ref } from 'vue';

// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Button from '@ice-products-ui/vue-library/Button';
import Card from '@ice-products-ui/vue-library/Card';

// Composables
import useGetData from '~/composables/api/useGetData';
import useForms from '~/composables/molecules/useForms';
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { IComponentProps } from '~/typings/types/component.types';
import type { TOPriceList } from '~/components/O/Price/List/OPriceList.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Schema
import schema from '~/components/O/Price/List/OPriceList.schema';

defineOptions({
  name: 'OPriceList',
});

const { requireForm, formList } = useForms();

const props = withDefaults(defineProps<IComponentProps<TOPriceList>>(), {
  viewName: 'OPriceList',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const { getComponent } = useGetData<TOPriceList>();

const complexityState = ref<boolean>(false);

const complexityCardTheme = computed(() => (idx: number) => {
  if (idx === 1) return 'light200';
  if (idx === 2) return 'light300';

  return undefined;
});

const complexitiesReveal = computed(() => (idx: number) => {
  if (!complexityState.value && idx === 0) {
    return true;
  }

  return complexityState.value;
});

const data = ref<TOPriceList>(getComponent(props).component);

useComponentSchemaValidator(schema, props);
</script>
